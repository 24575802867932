import { GoogleLogin } from '@react-oauth/google';
import withWidth from "@mui/material/Hidden/withWidth";
import {useLocation, useNavigate} from "react-router-dom";
import axios from "axios";
import React, {useState} from "react";
import Grid from "@mui/material/Unstable_Grid2";
import {CardMedia, Snackbar} from "@mui/material";
import {Alert} from "@mui/lab";

const LoginLanding = ({_}) => {
    const location = useLocation();
    const navigate = useNavigate();

    const getBatchIndex = (location) => {
        return location.state && location.state.hasOwnProperty(
            'batchIndex') && location.state.batchIndex
                    ? location.state.batchIndex : 0;
    }

    const getTitleLingo = (location) => {
        return location.state && location.state.hasOwnProperty(
            'titleLingo') && location.state.titleLingo
                    ? location.state.titleLingo : '';
    }

    const getEarlyLogin = (location) => {
        return location.state && location.state.hasOwnProperty(
            'earlyLogin') && location.state.earlyLogin
                    ? location.state.earlyLogin : false;
    }

    const [batchIndex] = useState(getBatchIndex(location));
    const [titleLingo] = useState(getTitleLingo(location));
    const [earlyLogin] = useState(getEarlyLogin(location));

    const [loginError, setLoginError] = useState('');

    const onLoginSuccess = (credentialResponse) => {
        axios.post(
            '/api/login', `${credentialResponse.credential}`, {
                headers: {'Content-Type': 'text/plain;charset=UTF-8'},
                withCredentials: true
            }
        ).then(
            _ => {
                // https://chromium.googlesource.com/chromium/src/+/c0b6446eb589b0f23885b0705b69c45932ea6313%5E%21/
                if (!earlyLogin) {
                    navigate(-1);
                } else {
                    // console.log('earlyLogin: ' + earlyLogin)
                    // console.log('batchIndex: ' + batchIndex)
                    // console.log('titleLingo: ' + titleLingo)

                    navigate('/', {
                        state: {
                            batchIndex: batchIndex,
                            titleLingo: titleLingo
                        },
                        replace: true
                    })
                }
            }
        ).catch(onLoginFailure);
    }

    const onLoginFailure = (loginResponseError) => {
        if (loginResponseError?.response?.status !== 401) {
            setLoginError('Failed to login!')
        } else {
            setLoginError('Failed to login: you must be on a whitelist to use title.fm!')
        }
    }

    return (
        <Grid container direction='column' spacing={0} justifyContent='center' alignItems='center' minHeight='100vh'>
            <Grid display='flex'>
                <CardMedia component='img' image='/logo.png' />
            </Grid>
            <Grid display='flex'>
                <GoogleLogin
                    onSuccess={onLoginSuccess}
                    onError  ={onLoginFailure}
                    useOneTap
                />
            </Grid>
            <Grid xs={12}>
                <Snackbar open={loginError !== ''}
                          anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
                          sx={{ width: '100%' }}
                          autoHideDuration={6000}
                          onClose = {() => setLoginError('')}>
                    <Alert severity='warning' variant='filled' sx={{ width: '100%' }} onClose = {() => setLoginError('')}>
                        {loginError}
                    </Alert>
                </Snackbar>
            </Grid>
        </Grid>
    );
}

export default withWidth()(LoginLanding);
