import React from 'react';
import {CardMedia, Typography} from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";

const VideoContent = ({ video, panel, onVideoSelected, onOwnerSelected }) => {
    const formatOwnerTitle = (title) => {
        let suffix = ' - topic';
        return !title.toLowerCase().endsWith(suffix)
            ? title : title.substring(
                0, title.length - suffix.length);
    }

    return (
        <Grid container xs={12} style={{cursor: 'pointer'}} onClick={() => panel === 1 ? onVideoSelected(video) : null}>
            <Grid xs={12}>
                <CardMedia
                    style={{backgroundColor: '#eeeeee'}}
                    component='img'
                    image={video.videoThumb[1] ? video.videoThumb[1] : video.videoThumb[0]}
                    alt  ={`${video.videoTitle} thumbnail`}
                    title={`${video.videoTitle} thumbnail`}
                    sx={{ borderRadius: {xs: '0px', sm: '12px'} }}
                    onClick={() => panel === 0 ? onVideoSelected(video) : null}
                />
            </Grid>

            <Grid xs={12} sx={{pl: {xs: 2, md: 0}, pt: 1 }}>
                {
                    panel === 0 &&
                    <Typography sx={{
                        overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}}>
                        <Typography component='span' variant='h7' sx={{ fontWeight: 600, color: '#666666' }}>
                            {video.trackTitle}
                        </Typography>

                        <Typography component='span' variant='h7' sx={{ fontWeight: 600, color: '#cccccc' }}>
                            {' ~ '}
                        </Typography>

                        <Typography component='span' variant='h7' sx={{ fontWeight: 600, color: '#222222' }}
                                    onClick={() => onOwnerSelected(video)}>
                            {formatOwnerTitle(video.ownerTitle)}
                        </Typography>
                    </Typography>
                }

                {
                    panel === 1 &&
                    <Typography sx={{
                        overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}}>
                        <Typography component='span' variant='h7' sx={{ fontWeight: 600, color: '#222222' }}>
                            {video.trackTitle}
                        </Typography>

                        <Typography component='span' variant='h7' sx={{ fontWeight: 600, color: '#cccccc' }}>
                            {' ~ '}
                        </Typography>

                        <Typography component='span' variant='h7' sx={{ fontWeight: 600, color: '#222222' }}>
                            {video.actorTitle}
                        </Typography>
                    </Typography>
                }
            </Grid>
        </Grid>
    );
};

export default VideoContent;
