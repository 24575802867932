import axios from 'axios';

export const fetchTitleDetails = async (
    watchVideo,
    classTrait,
    styleTrait) => {
    const response = await axios.get(
        '/api/titleDetails', {
            params: {
                watchVideo,
                classTrait,
                styleTrait
            },
            withCredentials: true
        });

    return response.data;
};
