import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import ActorLanding from './pages/ActorLanding';
import LoginLanding from './pages/LoginLanding';
import TitleExplore from './pages/TitleExplore';
import TitleLanding from './pages/TitleLanding';
import {createTheme, ThemeProvider} from "@mui/material";

export const theme = createTheme({
    typography: {
        button: {
            fontWeight: 600,
        },
    },
});

function App() {
  return (
      <ThemeProvider theme={theme}>
            <Router>
                <Routes>
                    <Route path="/"   element={<TitleExplore/>} />
                    <Route path="/lg" element={<LoginLanding/>} />
                    <Route path="/al" element={<ActorLanding/>} />
                    <Route path="/tl" element={<TitleLanding/>} />
                </Routes>
            </Router>
      </ThemeProvider>
  );
}

export default App;
