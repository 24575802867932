import React from 'react';
import ReactPlayer from "react-player/youtube";
import Grid from "@mui/material/Unstable_Grid2";
import {Avatar, Button, Stack, ToggleButton, ToggleButtonGroup, Typography} from "@mui/material";
import BookmarkAddIcon from '@mui/icons-material/BookmarkAdd';
import PlayPrevVideoIcon from '@mui/icons-material/SkipPrevious';
import PlayNextVideoIcon from '@mui/icons-material/SkipNext';


const WatchContent = (
    {
        title,
        video,
        onOwnerSelected,
        onVideoFinished,
        onPlayPrevVideo,
        onPlayNextVideo
    }) => {

    const formatOwnerTitle = (title) => {
        let suffix = ' - topic';
        return !title.toLowerCase().endsWith(suffix)
            ? title : title.substring(
                0, title.length - suffix.length);
    }

    return (
        <Grid container xs={12} sx={{ px: {xs: 0, md: 2}, pb: 2 }}>
            <Grid xs={12} style={{position: 'relative', paddingBottom: '56.25%'}}>
                <ReactPlayer
                    controls={true}
                    playing ={true}
                    loop={false}
                    onEnded ={() => {onVideoFinished(0)}}
                    onError ={() => {onVideoFinished(1)}}
                    url={'https://www.youtube.com/watch?v=' + video.videoKey}
                    style={{position: 'absolute', top: '0', left: '0'}}
                    width ='100%'
                    height='100%'
                />
            </Grid>

            <Grid container xs={12} sx={{ px: {xs: 1, md: 0}, pt: 1 }} alignItems='flex-start'>
                <Grid xs>
                    <Typography sx={{
                        overflow: 'hidden', textOverflow: 'ellipsis', display: '-webkit-box', WebkitLineClamp: 2, WebkitBoxOrient: 'vertical'}}>
                        <Typography component='span' sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 600, color: '#000000' }}>
                            {video.trackTitle}
                        </Typography>

                        <Typography component='span' sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 600, color: '#cccccc' }}>
                            {' ~ '}
                        </Typography>

                        <Typography component='span' sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 600, color: '#000000' }}>
                            {video.actorTitle}
                        </Typography>
                    </Typography>
                </Grid>

                <Grid xs='auto' sx={{ pl: 2 }} xsOffset='auto'>
                        <Typography noWrap sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 600, color: '#000000' }}>
                            {title.titleTotal}
                        </Typography>
                </Grid>

                <Grid xs='auto' sx={{ pl: 2 }} xsOffset='auto'>
                    <ToggleButtonGroup size='small'>
                        <ToggleButton value='0' size='small' sx={{ borderRadius: 28 }} onClick={() => {onPlayPrevVideo()}}>
                            <PlayPrevVideoIcon color='primary'/>
                        </ToggleButton>
                        <ToggleButton value='1' size='small' sx={{ borderRadius: 28 }} onClick={() => {onPlayNextVideo()}}>
                            <PlayNextVideoIcon color='primary'/>
                        </ToggleButton>
                    </ToggleButtonGroup>
                </Grid>
            </Grid>

            <Grid xs={12} sx={{ px: {xs: 1, md: 0}, pt: 0 }} alignItems='center'>
                <Stack direction='row' alignItems='center'
                       style={{cursor: 'pointer'}}
                       onClick={() => onOwnerSelected(video)}>
                    <Avatar src={video.ownerThumb[0]} alt={video.ownerTitle}/>

                    <Typography noWrap sx={{ fontSize: { xs: 22, md: 28 }, fontWeight: 600, color: '#000000', pl: 1 }}>
                        {formatOwnerTitle(video.ownerTitle)}
                    </Typography>

                    <Button variant="contained" color='secondary' startIcon={<BookmarkAddIcon/>} sx={{borderRadius: 28, ml: 2}}>SAVE</Button>
                </Stack>
            </Grid>

        </Grid>
    );
};

export default WatchContent;
