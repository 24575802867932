import React, {useEffect, useRef, useState} from 'react';
import VideoContent from "../components/VideoContent";
import {fetchTitleDetails} from "../api/TitleDetails";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {Chip, Container, Fab, Stack, Tab} from "@mui/material";
import Grid from '@mui/material/Unstable_Grid2';
import TitleSuggest from "../components/TitleSuggest";
import WatchContent from "../components/WatchContent";
import withWidth from "@mui/material/Hidden/withWidth";
import {QueueMusic} from "@mui/icons-material";
import Box from "@mui/material/Box";
import {TabContext, TabList, TabPanel} from "@mui/lab";

const TitleLanding = ({width}) => {
    const styleRef = useRef();
    const location = useLocation();
    const navigate = useNavigate();

    const [queryParam] = useSearchParams();

    const getTrackTitle = (location) => {
        return location.state && location.state.hasOwnProperty(
            'trackTitle') && location.state.trackTitle
                    ? location.state.trackTitle : '';
    }

    const getActorTitle = (location) => {
        return location.state && location.state.hasOwnProperty(
            'actorTitle') && location.state.actorTitle
                    ? location.state.actorTitle : '';
    }

    const getClassTrait = (location) => {
        return location.state && location.state.hasOwnProperty(
            'classTrait') && location.state.classTrait
                    ? location.state.classTrait : '';
    }

    const getStyleTrait = (location) => {
        return location.state && location.state.hasOwnProperty(
            'styleTrait') && location.state.styleTrait
                    ? location.state.styleTrait : '';
    }

    const getWatchVideo = (location) => {
        return location.state && location.state.hasOwnProperty(
            'watchVideo') && location.state.watchVideo
                    ? location.state.watchVideo
                            : queryParam.get('v');
    }

    const getWatchQueue = (location) => {
        return location.state && location.state.hasOwnProperty(
            'watchQueue') && location.state.watchQueue
                    ? location.state.watchQueue : [];
    }

    const getMusicPanel = (location) => {
        return location.state && location.state.hasOwnProperty(
            'musicPanel') && location.state.musicPanel
                    ? location.state.musicPanel :  0;
    }

    const [trackTitle, setTrackTitle] = useState(
        getTrackTitle(location));

    const [actorTitle, setActorTitle] = useState(
        getActorTitle(location));

    const [classTrait, setClassTrait] = useState(
        getClassTrait(location));

    const [styleTrait, setStyleTrait] = useState(
        getStyleTrait(location));

    const [watchVideo, setWatchVideo] = useState(
        getWatchVideo(location));

    const [watchQueue, setWatchQueue] = useState(
        getWatchQueue(location));

    const [musicPanel, setMusicPanel] = useState(
        getMusicPanel(location));

    const [musicTitle, setMusicTitle] = useState(
        {
            trackTitle: trackTitle,
            actorTitle: actorTitle,
            titleStyleTotal: {}
        }
    );

    if (trackTitle !== getTrackTitle(location) ||
        actorTitle !== getActorTitle(location) ||
        classTrait !== getClassTrait(location) ||
        styleTrait !== getStyleTrait(location) ||
        watchVideo !== getWatchVideo(location)) {

        setTrackTitle (getTrackTitle(location));
        setActorTitle (getActorTitle(location));
        setClassTrait (getClassTrait(location));
        setStyleTrait (getStyleTrait(location));
        setWatchVideo (getWatchVideo(location));
        setWatchQueue (getWatchQueue(location));
        setMusicPanel (getMusicPanel(location));
    }

    const [watchBlock, setWatchBlock] = useState([]);
    const [videoBlock, setVideoBlock] = useState([]);
    const [matchBlock, setMatchBlock] = useState([]);

    useEffect(() => {
        const updatePage = async (
                trackTitle,
                actorTitle,
                classTrait,
                styleTrait,
                watchVideo) => {
            try {
                // console.log('watchVideo: ' + watchVideo)
                // console.log('classTrait: ' + classTrait)
                // console.log('styleTrait: ' + styleTrait)

                const response = await fetchTitleDetails(
                    watchVideo,
                    classTrait,
                    styleTrait
                );

                setTrackTitle(trackTitle);
                setActorTitle(actorTitle);
                setClassTrait(classTrait);
                setStyleTrait(styleTrait);
                setWatchVideo(watchVideo);

                if (watchQueue.length === 0) {
                    setWatchQueue(
                        response.matchContent);
                }

                setMusicTitle(
                    response.titleContent[0]);

                setWatchBlock(
                    response.watchContent);
                setVideoBlock(
                    response.videoContent);
                setMatchBlock(
                    response.matchContent);

                if (styleRef.current) {
                    styleRef.current.scrollTo(
                        {left: 0});
                }
            } catch (error) {
                if (error?.response?.status === 401) {
                    navigate('/lg')
                } else {
                    console.error(
                        "Error fetching title details: ", error);
                }
            }
        };

        updatePage(
            trackTitle,
            actorTitle,
            classTrait,
            styleTrait,
            watchVideo).then();
    }, [
        trackTitle,
        actorTitle,
        classTrait,
        styleTrait,
        watchVideo]
    );

    const onVideoFinished = (onError) => {
        if (onError === 0) {
            onPlayNextVideo();
        }
    }

    const onPlayPrevVideo = () => {
        navigate(-1);
    }

    const onPlayNextVideo = (replace = 0) => {
        let queueIndex = getPlayNextIndex(watchQueue);
        if (queueIndex >= 0 && queueIndex < watchQueue.length) {
            navigateToQueue(
                watchQueue[queueIndex], replace);
        }
    }

    const getPlayNextIndex = (watchQueue) => {
        let queueIndex = watchQueue.findIndex(
            (video) => {return video.videoKey === watchVideo;})
        return queueIndex < 0 ? 0 : (queueIndex + watchQueue.length + 1) % watchQueue.length;
    }

    const onInputSelected = (
        video) => {
        let replace = 0;
        navigateToInput(
            video, replace);
    };

    const onVideoSelected = (video) => {
        let replace = 0;
        navigateToVideo(video, replace);
    };

    const onOwnerSelected = (video) => {
        let replace = 0;
        navigateToOwner(video, replace);
    };

    // noinspection JSUnusedLocalSymbols
    const onStyleSelected = (style) => {
        let replace = 1;
        navigateToStyle(style, replace);
    };

    const navigateToInput = (
        video, replace) => {
        let trackTitle = '';
        let actorTitle = '';
        let classTrait = '';
        let styleTrait = '';
        let watchVideo = video;

        navigate(`/tl?v=${watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo,
                },
                replace: replace
            });
    };

    const navigateToVideo = (
        video, replace) => {
        let trackTitle = video.trackTitle
        let actorTitle = video.actorTitle;
        let watchVideo = video.videoKey;

        navigate(`/tl?v=${watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo
                },
                replace: replace
            });

        window.scrollTo({top: 0, left: 0});
    };

    const navigateToQueue = (
        video, replace) => {
        let trackTitle = video.trackTitle
        let actorTitle = video.actorTitle;
        let watchVideo = video.videoKey;

        navigate(`/tl?v=${
                watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo,
                    watchQueue,
                },
                replace: replace
            });
    };

    const navigateToOwner = (
        video, replace) => {
        let videoOwner = video.ownerKey;

        navigate(`/al?a=${
                videoOwner}`,
            {
                replace: replace
            });
    };

    const navigateToStyle = (
        style, replace) => {
        let styleTrait = style;

        navigate(`/tl?v=${
            watchVideo}`,
            {
                state: {
                    trackTitle,
                    actorTitle,
                    classTrait,
                    styleTrait,
                    watchVideo
                },
                replace: replace
            });
    };

    const styleLabelString = ( style, count ) => {
        return `${style.toUpperCase()} (${count})`;
    }

    const styleColorString = ( style, _ ) => {
        return style === styleTrait
            ? 'secondary' : 'primary'
    }

    const styleTotalFilter = ([style, total]) => {
        return style === styleTrait
            || (style !== 'ai' && total >= 5);
    }

    const styleTotalSorter = (
        [style1, count1], [style2, count2]) => {
        return style1 === styleTrait
            ? -1 : style2 === styleTrait
                ? 1 : (count2 - count1);
    }

    const watchFilter = (video) => {
        return video.videoKey !== watchVideo;
    }

    const titleFilter = (video) => {
        return video.trackTitle !== musicTitle.trackTitle
            || video.actorTitle !== musicTitle.actorTitle;
    }

    const isSmallScreen = /xs|sm/.test(width);

    if (isSmallScreen) {
        return (
            <React.Fragment sx={{ position: 'relative' }}>
                <TitleSuggest key={watchVideo} onInputSelected={onInputSelected}/>

                <Stack>
                    {watchBlock?.map((watchVideo, watchIndex) => (
                        <WatchContent
                            key  ={watchIndex}
                            title={musicTitle}
                            video={watchVideo}
                            onOwnerSelected={onOwnerSelected}
                            onVideoFinished={onVideoFinished}
                            onPlayPrevVideo={onPlayPrevVideo}
                            onPlayNextVideo={onPlayNextVideo}
                        />
                    ))}

                    {
                        Object.entries(musicTitle.titleStyleTotal).filter(styleTotalFilter).length > 0 &&
                        <Grid container xs={12} ref={styleRef} flexWrap='nowrap' sx={{ overflowX: 'auto', py: 1 }}>
                            {Object.entries(musicTitle.titleStyleTotal)
                                .filter(styleTotalFilter).sort(styleTotalSorter)?.map(
                                    ([style, total], index) => (
                                        <Grid xs='auto' key={index} sx={{px: 1}}>
                                            <Chip
                                                color={styleColorString(style, total)}
                                                label={styleLabelString(style, total)}
                                                onClick={() => {onStyleSelected(
                                                    style === styleTrait ? '' : style)}}/>
                                        </Grid>
                                    )
                                )}
                        </Grid>
                    }

                    {
                        (matchBlock.filter(titleFilter).length > 0 || videoBlock.filter(watchFilter).length > 0) &&
                        <Grid container xs={12}>
                            <Grid xs={12}>
                                <TabContext value={musicPanel === 0  && videoBlock.filter(watchFilter).length > 0 ? '0' : '1'} >
                                    <Box>
                                        <TabList variant='fullWidth' onChange={(event, value) => {setMusicPanel(value === '0' ? 0 : 1)}}>
                                            {
                                                videoBlock.filter(watchFilter).length > 0 &&
                                                <Tab value='0' label='FROM TITLE' sx={{ borderBottom: 1, borderColor: 'divider' }}></Tab>
                                            }

                                            {
                                                matchBlock.filter(titleFilter).length > 0 &&
                                                <Tab value='1' label='LIKE TITLE' sx={{ borderBottom: 1, borderColor: 'divider' }}></Tab>
                                            }
                                        </TabList>
                                    </Box>

                                    <TabPanel value='0' sx={{ p: 0 }}>
                                        <Grid container xs={12}>
                                            {videoBlock.filter(watchFilter)?.map((blockVideo, blockIndex) => (
                                                <Grid key={blockIndex} xs={12} sx={{ px: {xs: 0, md: 2}, py: 1 }}>
                                                    <VideoContent
                                                        video={blockVideo}
                                                        panel={0}
                                                        onVideoSelected={onVideoSelected}
                                                        onOwnerSelected={onOwnerSelected}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value='1' sx={{ p: 0 }}>
                                        <Grid container xs={12}>
                                            {matchBlock.filter(titleFilter)?.map((blockVideo, blockIndex) => (
                                                <Grid key={blockIndex} xs={12} sx={{ px: {xs: 0, md: 2}, py: 1 }}>
                                                    <VideoContent
                                                        video={blockVideo}
                                                        panel={1}
                                                        onVideoSelected={onVideoSelected}
                                                        onOwnerSelected={onOwnerSelected}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </TabPanel>

                                </TabContext>

                            </Grid>
                        </Grid>
                    }

                    {
                        (matchBlock.filter(titleFilter).length > 0 || videoBlock.filter(watchFilter).length > 0) &&
                        <Fab sx = {{ position: 'fixed', bottom: 30, right: 30, border: 2 }}
                             disabled={JSON.stringify(watchQueue) === JSON.stringify(
                                 musicPanel === 0 ? videoBlock : matchBlock)}
                             color = 'primary'
                             onClick = {() => {
                                 setWatchQueue(musicPanel === 0 ? videoBlock : matchBlock)
                             }}>
                            <QueueMusic/>
                        </Fab>
                    }

                </Stack>

            </React.Fragment>
        );
    } else {
        return (
            <React.Fragment>
                <Container maxWidth='xl'>
                    <TitleSuggest key={watchVideo} onInputSelected={onInputSelected}/>

                    <Grid container>
                        <Grid xs>
                            <Stack>
                                {
                                    watchBlock?.map((watchVideo, watchIndex) => (
                                        <WatchContent
                                            key  ={watchIndex}
                                            title={musicTitle}
                                            video={watchVideo}
                                            onOwnerSelected={navigateToOwner}
                                            onVideoFinished={onVideoFinished}
                                            onPlayPrevVideo={onPlayPrevVideo}
                                            onPlayNextVideo={onPlayNextVideo}
                                        />
                                    ))
                                }

                                {
                                    Object.entries(musicTitle.titleStyleTotal).filter(styleTotalFilter).length > 0 &&
                                    <Grid container xs={12} ref={styleRef} flexWrap='nowrap' sx={{ overflowX: 'auto', py: 1 }}>
                                        {Object.entries(musicTitle.titleStyleTotal)
                                            .filter(styleTotalFilter).sort(styleTotalSorter)?.map(
                                                ([style, total], index) => (
                                                    <Grid xs='auto' key={index} sx={{px: 1}}>
                                                        <Chip
                                                            color={styleColorString(style, total)}
                                                            label={styleLabelString(style, total)}
                                                            onClick={() => {onStyleSelected(
                                                                style === styleTrait ? '' : style)}}/>
                                                    </Grid>
                                                )
                                            )}
                                    </Grid>
                                }

                                {
                                    (videoBlock.length > 1 || matchBlock.length > 1) &&
                                    <Grid container xs={12}>
                                        {(musicPanel === 0
                                                ? matchBlock.filter(titleFilter)
                                                : videoBlock.filter(watchFilter)
                                        )?.map((blockVideo, blockIndex) => (
                                            <Grid key={blockIndex} xs={12} sm={6} lg={4} sx={{ px: {xs: 0, md: 2}, py: 1 }}>
                                                <VideoContent
                                                    video={blockVideo}
                                                    panel={musicPanel === 0  ? 1 : 0}
                                                    onVideoSelected={onVideoSelected}
                                                    onOwnerSelected={onOwnerSelected}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                }
                            </Stack>
                        </Grid>

                        {
                            (matchBlock.filter(titleFilter).length > 0 || videoBlock.filter(watchFilter).length > 0) &&
                            <Grid xs='auto' style={{width: '320px'}} sx={{ pl: 2, position: 'relative' }}>
                                <Fab sx = {{ position: 'absolute', top: 90, right: 30, border: 2 }}
                                     disabled={JSON.stringify(watchQueue) === JSON.stringify(
                                         musicPanel === 0 ? videoBlock : matchBlock)}
                                     color = 'primary'
                                     onClick = {() => {
                                         setWatchQueue(musicPanel === 0 ? videoBlock : matchBlock)
                                     }}>
                                    <QueueMusic/>
                                </Fab>

                                <TabContext value={musicPanel === 0 && videoBlock.filter(watchFilter).length > 0 ? '0' : '1'} >
                                    <Box>
                                        <TabList variant='fullWidth' onChange={(event, value) => {setMusicPanel(value === '0' ? 0 : 1)}}>
                                            {
                                                videoBlock.filter(watchFilter).length > 0 &&
                                                <Tab value='0' label='FROM TITLE' sx={{ borderBottom: 1, borderColor: 'divider' }}></Tab>
                                            }

                                            {
                                                matchBlock.filter(titleFilter).length > 0 &&
                                                <Tab value='1' label='LIKE TITLE' sx={{ borderBottom: 1, borderColor: 'divider' }}></Tab>
                                            }
                                        </TabList>
                                    </Box>

                                    <TabPanel value='0' sx={{ p: 0 }}>
                                        <Grid container xs={12}>
                                            {videoBlock.filter(watchFilter)?.map((blockVideo, blockIndex) => (
                                                <Grid key={blockIndex} xs={12} sx={{ py: 1 }}>
                                                    <VideoContent
                                                        video={blockVideo}
                                                        panel={0}
                                                        onVideoSelected={onVideoSelected}
                                                        onOwnerSelected={onOwnerSelected}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </TabPanel>

                                    <TabPanel value='1' sx={{ p: 0 }}>
                                        <Grid container xs={12}>
                                            {matchBlock.filter(titleFilter)?.map((blockVideo, blockIndex) => (
                                                <Grid key={blockIndex} xs={12} sx={{ py: 1 }}>
                                                    <VideoContent
                                                        video={blockVideo}
                                                        panel={1}
                                                        onVideoSelected={onVideoSelected}
                                                        onOwnerSelected={onOwnerSelected}
                                                    />
                                                </Grid>
                                            ))}
                                        </Grid>
                                    </TabPanel>

                                </TabContext>

                            </Grid>
                        }
                    </Grid>

                </Container>
            </React.Fragment>
        );
    }
};

export default withWidth()(TitleLanding);
