import React, {useEffect, useState} from 'react';
import {AppBar, Autocomplete, CardMedia, Stack, TextField, Toolbar, Typography} from '@mui/material';
import {fetchTitleSuggest} from "../api/TitleSuggest";
import SearchIcon from '@mui/icons-material/Search';
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";
import Grid from "@mui/material/Unstable_Grid2";

const TitleSuggest = ({ onInputSelected }) => {
    const navigate = useNavigate();
    
    const [titleInput, setTitleInput] = useState('');
    const [titleBlock, setTitleBlock] = useState([]);

    useEffect(() => {
        const updateElem = async (
            titleInput
        ) => {
            try {
                const response = await fetchTitleSuggest(
                    titleInput
                );

                setTitleBlock(
                    !response.suggestTitle
                        ? [] : response.suggestTitle);
            } catch (error) {
                if (error?.response?.status === 401) {
                    navigate('/lg')
                } else {
                    console.error(
                        "Error fetching title suggest: ", error);
                }
            }
        };

        updateElem(titleInput).then();
    }, [titleInput]);

    const onInputChange = (event, value) => {
        setTitleInput(value);
    }

    const onTitleChange = (event, value) => {
        if (value && value.titleVideo) {
            onInputSelected(
                value.titleVideo);
        }
    }

    const getOptionLabel = (option, state) => {
        let input  = (state && state.inputValue) ? state.inputValue : ''

        let title1 = (option.trackTitle + ' ' + option.actorTitle)
        let title2 = (option.actorTitle + ' ' + option.trackTitle)

        return title1.startsWith(input) ? title1 : (title2.startsWith(input) ? title2 : title1);
    }

    return (
        <AppBar position="sticky" elevation={0} style={{ background: '#ffffff' }}>
            <Toolbar sx={{px: {xs: 1} }}>
                <Grid container xs={12} alignItems='center'>
                    <Grid xs={0}  md={2}>
                        <CardMedia component='img' image='/logo.png' sx={{maxWidth: 180}}/>
                    </Grid>
                    <Grid xs={12} md={8}>
                        <Autocomplete
                            freeSolo={true}
                            clearOnBlur={false}
                            filterOptions={(x) => x}
                            options={titleBlock}
                            getOptionLabel={getOptionLabel}
                            onChange={onTitleChange}
                            onInputChange={onInputChange}
                            sx={{
                                maxWidth: { xs: '100%', md: '60ch' }, ml: 'auto', mr: 'auto'
                            }}
                            ListboxProps={{
                                sx: { fontWeight: 600 },
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label = {'Search'}
                                    size  = {'small'}
                                    InputProps={{
                                        ...params.InputProps,
                                        style: { fontWeight: 400 }
                                    }}
                                />
                            )}
                            renderOption={(props, option, state) => {
                                let input = state.inputValue
                                let match = getOptionLabel(option, state)

                                return (
                                    <Box component='li' {...props}>
                                        <Stack direction='row' style={{ whiteSpace: 'pre' }} sx={{ color: '#000000' }}>
                                            <SearchIcon sx={{pr: 1, color: '#666666'}}/>
                                            {
                                                match.replaceAll(input, '|' + input + '|').split(
                                                    '|').filter((token) => {return token.length > 0}).map(
                                                    (token, _) => (
                                                        <Typography sx={{ fontWeight: token === input ? 600 : 400 }}>
                                                            {token}
                                                        </Typography>
                                                    )
                                                )
                                            }
                                        </Stack>
                                    </Box>
                                )
                            }}
                        />
                    </Grid>

                </Grid>
            </Toolbar>
        </AppBar>
    );
};

export default TitleSuggest;
